import React from 'react'
import { IoIosStar } from 'react-icons/io'

const DevRightSideBar = () => {
  return (
    <>
      <div className="staric-div">
        <div className="icon">
          <IoIosStar />
        </div>
      </div>
    </>
  )
}

export default DevRightSideBar