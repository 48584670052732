import React from 'react'

const FooterGlobal = () => {
  return (
    <div className='footer-global'>
      <p className='copyright-box'>
        &copy; Copyright {new Date().getFullYear()}. CDH WebCraft
      </p>
    </div>
  )
}

export default FooterGlobal