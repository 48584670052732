import React from "react";
import { NavLink } from "react-router-dom";
import { VscDashboard } from "react-icons/vsc";
import { MdInsights, MdOutlineAdminPanelSettings } from "react-icons/md";
import { FaCaretDown, FaCode, FaRegUser } from "react-icons/fa";
import { SiElementary } from "react-icons/si";
import { HiOutlineTemplate } from "react-icons/hi";
import { LuSettings } from "react-icons/lu";

const SidebarDashboard = () => {
  return (
    <nav className="SidebarDashboard">
      <ul>
        {/* Dashboard */}
        <li>
          <NavLink to="/cdh/dashboard">
            <span className="icon">
              <VscDashboard />
            </span>
            Dashboard
          </NavLink>
        </li>

        {/* Insights */}
        <li>
          <NavLink to="/cdh/insights">
            <span className="icon">
              <MdInsights />
            </span>
            Insights
          </NavLink>
        </li>

        {/* Settings Dropdown */}
        <li>
          <NavLink to="/cdh/setting">
            <span className="icon">
              <LuSettings />
            </span>
            Settings
            <span className="down-arrow">
              <FaCaretDown />
            </span>
          </NavLink>
          <ul>
            <li>
              <NavLink to="/cdh/setting/elements">
                <span className="icon">
                  <SiElementary />
                </span>
                Elements
              </NavLink>
            </li>
            <li>
              <NavLink to="/cdh/setting/templates">
                <span className="icon">
                  <HiOutlineTemplate />
                </span>
                Templates
              </NavLink>
            </li>
            <li>
              <NavLink to="/cdh/setting/code">
                <span className="icon">
                  <FaCode />
                </span>
                Code
              </NavLink>
            </li>
          </ul>
        </li>

        {/* User */}
        <li>
          <NavLink to="/cdh/user">
            <span className="icon">
              <FaRegUser />
            </span>
            User
          </NavLink>
        </li>

        {/* Admin */}
        <li>
          <NavLink to="/cdh/admin">
            <span className="icon">
              <MdOutlineAdminPanelSettings />
            </span>
            Admin
          </NavLink>
        </li>
      </ul>
    </nav>
  );
};

export default SidebarDashboard;
