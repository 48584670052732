import React, { useEffect, useState } from 'react';
import { useOutletContext } from "react-router-dom";
import DevCodeItemCard from './DevCodeItemCard';
import Img from '../../media/code/tech.jpg';

// Array of table styles
const tableItems = [
  { Title: 'Table Style 101', Img: Img, Link: '' },
  { Title: 'Table Style 102', Img: Img, Link: '' },
  { Title: 'Table Style 103', Img: Img, Link: '' }
];

const CodeTable = () => {
  const [currentItems, handleOutletItems] = useOutletContext();
  const [loading, setLoading] = useState(true); // State to track loading

  useEffect(() => {
    const DevCodeLoaderTimer = 800;

    setLoading(true); // Set loading to true when component mounts
    handleOutletItems(tableItems);

    // Simulate a loading delay
    const loaderTimeout = setTimeout(() => {
      setLoading(false); // Set loading to false after delay
    }, DevCodeLoaderTimer); // 700ms delay for skeletons

    return () => clearTimeout(loaderTimeout); // Cleanup timeout
  }, [handleOutletItems]);

  return (
    <div className='DevCodeOutlets-outer'>
      {loading ? (
        <>
          {currentItems.map((_, index) => (
            <div className="skeleton" key={index}></div>
          ))}
        </>
      ) : (
        currentItems.map((table, index) => (
          <DevCodeItemCard
            key={index}
            Title={table.Title}
            Img={table.Img}
            Link={table.Link}
          />
        ))
      )}
    </div>
  );
};

export default CodeTable;
