import React from 'react'

const ServicePageCard = (props) => {
  return (
    <div className="service-card">
      <div className="icon-box">{props.Icon}</div>
      <h3>{props.Service}</h3>
    </div>
  )
}

export default ServicePageCard