import React from 'react'

const ServiceListSkeleton = () => {
  return (
    <>
      <div className='skeleton'></div>
      <div className='skeleton'></div>
      <div className='skeleton'></div>
      <div className='skeleton'></div>
      <div className='skeleton'></div>
      <div className='skeleton'></div>
    </>
  )
}

export default ServiceListSkeleton