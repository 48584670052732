import React, { useEffect, useState } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";

const DevSideBarMenu = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [loading, setLoading] = useState(true);
  const [subNavItems, setSubNavItems] = useState([]);

  // Handle loading state on route change
  useEffect(() => {
    // Reset loading to true when the route changes
    setLoading(true);

    const timeout = setTimeout(() => {
      setLoading(false);
    }, 500); // Simulate a delay for loading state

    return () => clearTimeout(timeout);
  }, [location.pathname]); // Trigger this effect when the route changes

  // Handle sub-navigation items based on the route
  useEffect(() => {
    switch (location.pathname) {
      case "/dev":
        setSubNavItems(HomeDevSubNav);
        break;
      case "/dev/elements":
        setSubNavItems(ElementSubNav);
        break;
      case "/dev/templetes": // Corrected typo from 'templetes'
        setSubNavItems(TempleteSubNav);
        break;
      case "/dev/code-snippet":
        setSubNavItems(CodeSubNav);
        break;
      case "/dev/code-snippet/button":
        setSubNavItems(ButtonCodeSubNav);
        break;
      case "/dev/code-snippet/hover":
        setSubNavItems(HoverCodeSubNav);
        break;
      case "/dev/code-snippet/table":
        setSubNavItems(TableCodeSubNav);
        break;
      case "/dev/code-snippet/form":
        setSubNavItems(FormCodeSubNav);
        break;
      case "/dev/code-snippet/list":
        setSubNavItems(ListCodeSubNav);
        break;
      default:
        setSubNavItems([]);
        break;
    }
  }, [location.pathname]);

  return (
    <section className="DevSideBarMenu">
      <ul className="content-nav">
        {loading ? (
          subNavItems.map((_, index) => (
            <li key={index}>
              <div className="skeleton"></div>
            </li>
          ))
        ) : (
          subNavItems.map((item, index) => (
            <li key={index}>
              <Link to={item.url}>{item.text}</Link>
            </li>
          ))
        )}
      </ul>
      <div className="back-to-site-dev">
        <button
          className="btn-back-to-site"
          onClick={() => {
            navigate("/");
          }}
        >
          Back to Site
        </button>
      </div>
    </section>
  );
};

// Define your sub-navigation arrays
const HomeDevSubNav = [
  { text: "Free Template", url: "/dev/free" },
  { text: "Paid Template", url: "/dev/paid" },
  { text: "Code Snippet", url: "/dev/snippet" },
  { text: "CSS Styling", url: "/dev/css" },
  { text: "Web Development", url: "/dev/web-dev" },
];

const ElementSubNav = [
  { text: "Tutorials", url: "/dev/elements/tutorials" },
  { text: "Guides", url: "/dev/elements/guides" },
  { text: "Documentation", url: "/dev/elements/docs" },
  { text: "The Dev Juzo", url: "/dev/elements/dev-juzo" },
  { text: "Kadem Pro", url: "/dev/elements/kadem-pro" },
  { text: "WD Shani", url: "/dev/elements/wd-shani" },
  { text: "Incog. Brand", url: "/dev/elements/incog-brand" },
  { text: "The School in.", url: "/dev/elements/school-in" },
];

const TempleteSubNav = [
  { text: "WD Shani", url: "/dev/templates/wd-shani" },
  { text: "The Dev Juzo", url: "/dev/templates/dev-juzo" },
  { text: "Guides", url: "/dev/templates/guides" },
  { text: "Kadem Pro", url: "/dev/templates/kadem-pro" },
  { text: "Incog. Brand", url: "/dev/templates/incog-brand" },
  { text: "Documentation", url: "/dev/templates/docs" },
  { text: "Tutorials", url: "/dev/templates/tutorials" },
];

const CodeSubNav = [
  { text: "Buttons", url: "/dev/code-snippet/button" },
  { text: "Hover", url: "/dev/code-snippet/hover" },
  { text: "Forms", url: "/dev/code-snippet/form" },
  { text: "Table", url: "/dev/code-snippet/table" },
  { text: "List", url: "/dev/code-snippet/list" },
];

const ButtonCodeSubNav = [
  { text: "Send", url: "/dev/code-snippet/button/send" },
  { text: "Submit", url: "/dev/code-snippet/button/submit" },
  { text: "View All", url: "/dev/code-snippet/button/view-all" },
];

const HoverCodeSubNav = [
  { text: "Containers", url: "/dev/code-snippet/hover/containers" },
  { text: "Cards", url: "/dev/code-snippet/hover/cards" },
];

const ListCodeSubNav = [
  { text: "Unordered List", url: "/dev/code-snippet/list/unordered-list" },
  { text: "Ordered List", url: "/dev/code-snippet/list/ordered-list" },
];

const FormCodeSubNav = [
  { text: "Login", url: "/dev/code-snippet/form/login" },
  { text: "Input", url: "/dev/code-snippet/form/input" },
  { text: "Radio", url: "/dev/code-snippet/form/radio" },
  { text: "Checkbox", url: "/dev/code-snippet/form/checkbox" },
];

const TableCodeSubNav = [
  { text: "T-Head", url: "/dev/code-snippet/table/head" },
  { text: "T-Row", url: "/dev/code-snippet/table/row" },
  { text: "Column", url: "/dev/code-snippet/table/column" },
];

export default DevSideBarMenu;
