import React, { useEffect } from "react";
import { BrowserRouter as Router, Routes, Route, useLocation, Navigate } from "react-router-dom";
import ReactGA from 'react-ga4';
import axios from 'axios';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';

// Style imports
import "./App.css";
import "./style/Skeleton.css";
import "./style/DevStyle.css";
import "./style/Dashboard.css";
// Responsiveness
import "./style/responsiveness/index.css";

// Component imports
import {
  Banner,
  Contact,
  About,
  HomeAboutSection,
  ServicesSwiper,
  FooterGlobal,
  ServicesPage,
  WhyChooseUs,
  TopBarAnnouncmentBar,
  MainHeader,
  CTABanner,
  NewsletterSection,
  DevPageLayout,
  DevSearchResult,
  ErrorPage404,
  DevTempletesPage,
  DevCodePage,
  DevElementsPage,
  CodeHover,
  CodeForm,
  CodeTable,
  CodeList,
  CodeButton,
  AdminLogin,
  DashboardLayout,
  InsightsDashboard,
  DashboardIndex,
  ElementSettingDashboard,
  TemplatesSettingDashboard,
  CodeSettingDashboard,
  UserDashboard,
  AdminDashboard,
  FooterDev
} from './components'; // Assuming you have an index.js in the components folder

function App() {
  useEffect(() => {
    ReactGA.initialize("G-5Q6XQLYMCX"); // Replace with your Google Analytics tracking ID
    ReactGA.send("pageview");
  }, []);

  return (
    <Router>
      <ScrollToTop />
      <Routing />
    </Router>
  );
}

function ScrollToTop() {
  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  return null;
}

function Routing() {
  const location = useLocation();
  const isAdminOrDevLocation = location.pathname.includes("/dev") || location.pathname.includes("/cdh");

  return (
    <div className="device-width-container">
      {!isAdminOrDevLocation &&
        <>
        <TopBarAnnouncmentBar />
        <MainHeader />
        </>
      }
      <Routes>
        <Route path="/" element={<HomeLayout />} />
        <Route path="/services" element={<ServicesPage />} />
        <Route path="/about" element={<About />} />
        <Route path="/contact" element={<Contact />} />
        
        {/* Admin Routes */}
        <Route path="/cdh/admin-access/secure/login" element={<AdminLogin />} />
        {/* Dashboard Routing */}
        <Route path="/cdh/" element={<DashboardLayout />} >
          <Route index element={<Navigate to='dashboard' />} />
          <Route path="dashboard" element={<DashboardIndex />} />
          <Route path="insights" element={<InsightsDashboard />} />
          <Route path="setting">
            <Route index element={<Navigate to='elements' />} />
            <Route path="elements" element={<ElementSettingDashboard />} />
            <Route path="templates" element={<TemplatesSettingDashboard />} />
            <Route path="code" element={<CodeSettingDashboard />} />
          </Route>
          <Route path="user" element={<UserDashboard />} />
          <Route path="admin" element={<AdminDashboard />} />
          <Route path="*" element={<ErrorPage404 />} />
        </Route>

        {/* Developer routes */}
        <Route path="/dev" element={<DevPageLayout />}>
          <Route path="elements" element={<DevElementsPage />} />
          <Route path="templetes" element={<DevTempletesPage />} />
          <Route path="search" element={<DevSearchResult />} />
          <Route path="code-snippet" element={<DevCodePage />}>
            <Route path="button" element={<CodeButton />} />
            <Route path="hover" element={<CodeHover />} />
            <Route path="form" element={<CodeForm />} />
            <Route path="table" element={<CodeTable />} />
            <Route path="list" element={<CodeList />} />
          </Route>
          <Route path="*" element={<ErrorPage404 />} />
        </Route>
        <Route path="*" element={<ErrorPage404 />} />
      </Routes>
      {/* Footer */}
      {!isAdminOrDevLocation ? <FooterGlobal /> : <FooterDev />}
    </div>
  );
}

function HomeLayout() {
  return (
    <>
      <Banner />
      <HomeAboutSection />
      <WhyChooseUs />
      <ServicesSwiper />
      <CTABanner />
      <NewsletterSection />
    </>
  );
}

export default App;
