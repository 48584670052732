import React from "react";
import { FaFacebook, FaGithub, FaLinkedin, FaPhoneAlt } from "react-icons/fa";
import { MdEmail } from "react-icons/md";

const TopBarAnnouncmentBar = () => {
  return (
    <div className="announcement-banner">
      <div className="banner-detail">
        <div className="contact">
          <div className="icon">
            <FaPhoneAlt />
          </div>
          <p className="text">+92 323 594-8057</p>
        </div>
        <div className="contact">
          <div className="icon">
            <MdEmail />
          </div>
          <p className="text">contact@cdhwebcraft.com</p>
        </div>
      </div>
      <div className="social">
        <a
          href="https://www.facebook.com/cdh.webcraft"
          alt=""
          target="__blank__"
          className="icon facebook"
        >
          <FaFacebook />
        </a>
        <a
          href="https://www.linkedin.com/in/shani-cdh/"
          alt=""
          target="__blank__"
          className="icon linkedin"
        >
          <FaLinkedin />
        </a>
        <a
          href="https://github.com/shanigtech"
          alt=""
          target="__blank__"
          className="icon github"
        >
          <FaGithub />
        </a>
      </div>
    </div>
  );
};

export default TopBarAnnouncmentBar;
