import React, { useEffect, useState } from "react";
import PaginationSection from "./innercomponents/PaginationSection";
import Element from "../media/elements/element.jpeg";
import { IoIosEye } from "react-icons/io";
import { Link, useLocation } from "react-router-dom";

const DevElementsPage = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 8;
  const [loading, setLoading] = useState(true); // Loading state
  const location = useLocation(true);
  const idBase = 100;

  const elements = [
    { id: idBase + 1, title: "Button No. 1", url: "#", img: Element },
    { id: idBase + 2, title: "Button No. 2", url: "#", img: Element },
    { id: idBase + 3, title: "Button No. 3", url: "#", img: Element },
    { id: idBase + 4, title: "Button No. 4", url: "#", img: Element },
    { id: idBase + 5, title: "Button No. 5", url: "#", img: Element },
    { id: idBase + 6, title: "Button No. 6", url: "#", img: Element },
    { id: idBase + 7, title: "Button No. 7", url: "#", img: Element },
    { id: idBase + 8, title: "Button No. 8", url: "#", img: Element },
    { id: idBase + 9, title: "Button No. 9", url: "#", img: Element },
    { id: idBase + 10, title: "Button No. 10", url: "#", img: Element },
  ];

  const sideBarMenu = [
    { title: "The Dev Juzo", url: "Button No. 8" },
    { title: "Kadem Pro", url: "Button No. 6" },
    { title: "WD Shani", url: "Button No. 10" },
    { title: "Incog. Brand", url: "Button No. 3" },
    { title: "The School in.", url: "Button No. 9" },
  ];
  // Calculate total pages based on the number of elements
  const totalPages = Math.ceil(elements.length / itemsPerPage);
  const currentElements = elements.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  useEffect(() => {
    const loaderTimeout = setTimeout(() => {
      setLoading(false); // Simulate loading for 1 second
    }, 700);

    return () => clearTimeout(loaderTimeout); // Cleanup timeout
  }, [location.pathname]);

  return (
    <div className="DevElementsPage">
      {/* Display current elements */}
      <div className="element-list">
        {loading ? (
          // Show skeletons while loading
          Array.from({ length: itemsPerPage }).map((_, index) => (
            <div key={index} className="element-item skeleton"></div>
          ))
        ) : currentElements.length > 0 ? (
          currentElements.map((element, index) => (
            <Link
              to={element.url}
              key={index}
              id={element.id}
              className="element-item watermark"
            >
              <div className="img-details-outer">
                <img src={element.img} alt="" />
              </div>
              <div className="title-view-div">
                <h5>{element.title}</h5>
                {/* <div className="views">
                  <span><IoIosEye /></span>
                  <span className='views-counter'>200997</span>
                </div> */}
              </div>
              <div className="watermark__card">
                <h1>CDH</h1>
              </div>
            </Link>
          ))
        ) : (
          <p>No elements found</p>
        )}
      </div>

      {/* Pagination */}
      {totalPages > 1 && (
        <PaginationSection
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          totalPages={totalPages}
        />
      )}
    </div>
  );
};

export default DevElementsPage;
