import React, { useEffect, useState } from "react";
import ServicePageCard from "./innercomponents/ServicePageCard";
import { FaArrowRight, FaBuilding, FaDesktop, FaGlobe } from "react-icons/fa";
import { MdDone, MdInsights, MdSupportAgent } from "react-icons/md";
import { FaChartColumn } from "react-icons/fa6";
import { PiPuzzlePiece } from "react-icons/pi";
import { Link } from "react-router-dom";
import ServiceListSkeleton from "./skeletons/ServiceListSkeleton";

const ServicesPage = () => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const TimeSecond = Math.random() * 700;
    const timer = setTimeout(() => {
      setLoading(false);
    }, TimeSecond);

    return () => clearTimeout(timer);
  }, []);

  return (
    <div className="service-page">
      <h2>We Offer!</h2>
      <div className="service-outer">
        {loading ? <ServiceListSkeleton /> : <ServicesList />}
      </div>
      <div className="service-reach">
        <Link to="/contact">
          <button type="button">
            Reach Us <FaArrowRight />
          </button>
        </Link>
      </div>
    </div>
  );

  function ServicesList() {
    return (
      <>
        <ServicePageCard Icon={<FaGlobe />} Service="Website Development" />
        <ServicePageCard Icon={<FaDesktop />} Service="Web App Development" />
        <ServicePageCard Icon={<MdInsights />} Service="Digital Marketing" />
        <ServicePageCard
          Icon={<FaChartColumn />}
          Service="Social Media Marketing"
        />
        <ServicePageCard
          Icon={<FaBuilding />}
          Service="Free & Premium Web Templates"
        />
        <ServicePageCard Icon={<PiPuzzlePiece />} Service="Web Elements" />
        <ServicePageCard Icon={<MdDone />} Service="Pre Build Websites" />
        <ServicePageCard Icon={<MdSupportAgent />} Service="24/7 Support" />
      </>
    );
  }
};

export default ServicesPage;
