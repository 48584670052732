import React from 'react'
import { useNavigate } from 'react-router-dom'

const CTABanner = () => {
  const navigate = useNavigate();

  return (
    <div className='CTABanner'>
      <h1 className='cta-heading'>Ready to take your online presence to the next level?</h1>
      <button
        className='cta-btn'
        onClick={() => {navigate('/contact')}}
      ></button>
    </div>
  )
}

export default CTABanner