import React, { useEffect } from 'react';
import { MdOutlineReportGmailerrorred } from "react-icons/md";
import { useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet';

const ErrorPage404 = () => {
  const location = useLocation();

  const goBack = () => {
    window.history.back();
  };

  useEffect(() => {
    const page = document.querySelector('.ErrorPage404');
    page.style.transform = 'scale(0)'; // Initially shrunk

    const handleTransform = () => {
      setTimeout(() => {
        page.style.transition = 'transform 0.5s ease'; // Smooth transition
        page.style.transform = 'scale(1)'; // Scale up to 1
      }, 50); // Slight delay to ensure transition applies after mounting
    };

    handleTransform();

    return () => {
      // Reset the scale on unmount for smooth exit transitions
      page.style.transform = 'scale(0)';
    };
  }, [location.pathname]);

  return (
    <div key={location.pathname} className='ErrorPage404'>
      <Helmet>
        <title>404 - Page Not Found</title>
        <meta name="description" content="The page you're looking for does not exist. Please navigate back to a valid page." />
      </Helmet>
      <div className="icon">
        <MdOutlineReportGmailerrorred />
      </div>
      <h1>Page Not Found!</h1>
      <p>The page you are looking for is not available yet.</p>
      <button onClick={goBack}>Go Back</button>
    </div>
  );
};

export default ErrorPage404;
