import React, { useEffect, useState } from 'react';
import { useOutletContext } from "react-router-dom";
import DevCodeItemCard from './DevCodeItemCard';
import Img from '../../media/code/buttons.jpg';

const buttonItems = [
  { Title: 'Button Style 101', Img: Img, Link: '' },
  { Title: 'Button Style 102', Img: Img, Link: '' },
  { Title: 'Button Style 103', Img: Img, Link: '' },
  { Title: 'Button Style 104', Img: Img, Link: '' },
  { Title: 'Button Style 105', Img: Img, Link: '' },
  { Title: 'Button Style 106', Img: Img, Link: '' },
  { Title: 'Button Style 107', Img: Img, Link: '' },
  { Title: 'Button Style 108', Img: Img, Link: '' },
  { Title: 'Button Style 109', Img: Img, Link: '' },
  { Title: 'Button Style 110', Img: Img, Link: '' }
];

const CodeButton = () => {
  const [currentItems, handleOutletItems] = useOutletContext();
  const [loading, setLoading] = useState(true); // Loading state

  useEffect(() => {
    const DevCodeLoaderTimer = 800;

    setLoading(true);
    handleOutletItems(buttonItems);

    const loaderTimeout = setTimeout(() => {
      setLoading(false);
    }, DevCodeLoaderTimer); // 700ms delay for skeletons

    return () => clearTimeout(loaderTimeout); // Cleanup timeout
  }, [handleOutletItems]);

  return (
    <div className='DevCodeOutlets-outer'>
      {loading ? (
        // Dynamically create skeleton loaders based on the length of currentItems
        <>
          {currentItems.map((_, index) => (
            <div className="skeleton" key={index}></div>
          ))}
        </>
      ) : (
        currentItems.map((button, index) => (
          <DevCodeItemCard
            key={index}
            Title={button.Title}
            Img={button.Img}
            Link={button.Link}
          />
        ))
      )}
    </div>
  );
};

export default CodeButton;
