import React from 'react'
import BenifitImg from '../media/img/benifit img.jpg'
import ChoosenListItem from './innercomponents/ChoosenListItem'
import { VscWorkspaceTrusted } from "react-icons/vsc";
import { TbTruckDelivery } from "react-icons/tb";
import { MdOutlineHighQuality } from "react-icons/md";
import { ImPriceTags } from "react-icons/im";
import { IoMdTimer } from "react-icons/io";
import { GiFastArrow } from "react-icons/gi";

const WhyChooseUs = () => {
  // Define an array of reasons with icons, headings, and descriptions
  const reasons = [
    {
      Icon: <GiFastArrow />,
      Heading: 'Fast Processing',
      Desc: 'Delivering high-quality results with fast and efficient processing.'
    },
    {
      Icon: <VscWorkspaceTrusted />,
      Heading: 'Trusted Service',
      Desc: 'Dependable and client-focused solutions you can rely on.'
    },
    {
      Icon: <TbTruckDelivery />,
      Heading: 'On-Time Delivery',
      Desc: 'Guaranteed On-Time Delivery for Reliable, Timely Results.'
    },
    {
      Icon: <MdOutlineHighQuality />,
      Heading: 'Reliable Quality',
      Desc: 'Delivering Consistent Excellence in Every Project.'
    },
    {
      Icon: <ImPriceTags />,
      Heading: 'Reasonable Price',
      Desc: 'Offering High-Quality Services at Reasonable, Competitive Prices.'
    },
    {
      Icon: <IoMdTimer />,
      Heading: '24/7 Service',
      Desc: 'I offer 24/7 service for anytime guidance and support.'
    }
  ];

  return (
    <div className='why-choose-me'>
      <div className='choose-visual'>
        <img src={BenifitImg} alt="" />
      </div>
      <div className='choose-data section'>
        <h6 className='type'>
          <div className="breaker">
            <span></span>
            <span></span>
          </div>
          Why?
        </h6>
        <h2 className='title'>
          Reasons, why to choose Me!
        </h2>
        <p className='detail'>
          See why clients choose me for my commitment to quality, timely delivery, and exceptional service tailored to your needs.
        </p>
        <div className="choosen-list">
          {reasons.map((reason, index) => (
            <ChoosenListItem
              key={index}
              Icon={reason.Icon}
              Heading={reason.Heading}
              Desc={reason.Desc}
            />
          ))}
        </div>
      </div>
    </div>
  )
}

export default WhyChooseUs
