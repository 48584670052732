import React from 'react'

const FeatureServiceCard = (props) => {
  return (
    <div className='FeatureServiceCard'>
      <div className="feature-service-icon">{props.Icon}</div>
      <h3>{props.Title}</h3>
      <p>{props.Content}</p>
    </div>
  )
}

export default FeatureServiceCard