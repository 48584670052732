import React from 'react'
import FeatureServiceCard from './innercomponents/FeatureServiceCard'

import { IoDiamond } from "react-icons/io5";
import { PiHourglassHighFill } from "react-icons/pi";
import { GiRevolt } from "react-icons/gi";

import AboutPeopleImg from '../media/img/about (people).jpg'
import { useNavigate } from 'react-router-dom';

const HomeAboutSection = () => {
  const navigate = useNavigate();

  // Define an array of service objects
  const services = [
    {
      Icon: <IoDiamond />,
      Title: 'Pixel Perfect',
      Content: 'As an expert web developer, I craft pixel-perfect websites with precision and elegance, ensuring seamless user experiences that impress.'
    },
    {
      Icon: <PiHourglassHighFill />,
      Title: 'High Quality',
      Content: 'As an expert web developer, I prioritize high-quality websites, ensuring functionality, aesthetics, and user experience meet the highest standards.'
    },
    {
      Icon: <GiRevolt />,
      Title: 'Awesome Idea',
      Content: 'With expertise in web development, I turn ideas into innovative digital experiences, blending creativity with technical skill to captivate audiences and drive client success.'
    }
  ];

  return (
    <div className='home-about-container'>
      <div className="feature-services-list">
        {services.map((service, index) => (
          <FeatureServiceCard
            key={index}
            Icon={service.Icon}
            Title={service.Title}
            Content={service.Content}
          />
        ))}
      </div>
      <div className="about-details">
        <div className="about-side image">
          <div className="experience-show-box">
            <h4>
              <span>2+</span>
              <span>
                Year of <br />Experience
              </span>
            </h4>
          </div>
          <div className="image-container">
            <img src={AboutPeopleImg} alt="" /> {/* Other Images are coming from ::before & ::after */}
          </div>
        </div>
        <div className="about-side content section">
          <h6 className='type'>
            <div className="breaker">
              <span></span>
              <span></span>
            </div>
            About Us!
          </h6>
          <h2 className='title'>I Can Build Any Website You Need.</h2>
          <p className='detail'>
            With my expertise in web development, I possess the capability to create any website you desire. Whether it's an e-commerce platform, a portfolio site, or a corporate webpage, I have the skills to bring your vision to life. From complex functionalities to stunning designs, I specialize in crafting tailor-made solutions that meet your specific needs and exceed your expectations. Let me transform your ideas into a fully functional and visually captivating online presence, delivering results that elevate your online presence and drive success.
          </p>
          <button
            type='button'
            onClick={() => navigate('/contact')}
          >
            Hire Me
          </button>
        </div>
      </div>
    </div>
  )
}

export default HomeAboutSection
