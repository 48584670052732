import React from 'react';

const FAQListItem = (props) => {
  return (
    <div className="faq-list-item">
      <input
        type="radio"
        name="faq-list"
        id={`faq-${props.Id}`}
        defaultChecked={props.defaultChecked} // Use `defaultChecked` here as passed in FAQList
      />
      <label htmlFor={`faq-${props.Id}`}>
        <h5 className="question">{props.Question}</h5>
        <p className="answer">{props.Answer}</p>
      </label>
    </div>
  );
};

export default FAQListItem;
