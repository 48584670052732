import React, { useState } from 'react';

const AdminLogin = () => {
  // State to handle form inputs
  const [formData, setFormData] = useState({
    username: '',
    password: ''
  });

  // Handle input changes
  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  // Handle form submission
  const handleSubmit = (e) => {
    e.preventDefault();
    // Logic to handle login (e.g., authenticate user)
    console.log('Login data:', formData);
    // You can integrate API or authentication logic here
  };

  return (
    <div className="admin-login-container">
      <h2 className="admin-login-title">Admin Login</h2>
      <form onSubmit={handleSubmit} className="admin-login-form">
        {/* Username input */}
        <label className="admin-login-label">
          Username:
          <input 
            type="text" 
            name="username" 
            value={formData.username} 
            onChange={handleChange} 
            required 
            className="admin-login-input"
          />
        </label>
        
        {/* Password input */}
        <label className="admin-login-label">
          Password:
          <input 
            type="password" 
            name="password" 
            value={formData.password} 
            onChange={handleChange} 
            required 
            className="admin-login-input"
          />
        </label>

        {/* Submit button */}
        <button type="submit" className="admin-login-button">Login</button>
      </form>
    </div>
  );
};

export default AdminLogin;
