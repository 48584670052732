import React, { useState, useEffect } from "react";

import {
  MdOutlineMail,
  MdOutlineMap,
  MdOutlinePhone,
} from "react-icons/md";

import {
  FaFacebook,
  FaGithub,
  FaLinkedin,
} from "react-icons/fa6";

import ContactPageSkeleton from "./skeletons/ContactPageSkeleton";
import NewsletterSection from "./innercomponents/NewsletterSection";

const ContactPage = () => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const randomSeconds = Math.random() * 700
    // Simulating a network request or loading time
    const timer = setTimeout(() => {
      setLoading(false);
    }, `${randomSeconds}`); // Adjust the timing as needed

    return () => clearTimeout(timer);
  }, []);

  return (
    <div>
            <h2 className="contact-title-style">Contact</h2>
      {loading ? (
        <ContactPageSkeleton />
      ) : (
        <ContactPageContent />
      )}
    </div>
  );


  function ContactPageContent() {
    return (
      <>
        <div className="contact-page-body">
          <div className="contact-page-header">
            <p>
              We'd love to hear from you! Whether you have questions, need
              assistance, or want to discuss your project, feel free to reach
              out using any of the methods below. We're always happy to connect
              and provide the support you need. Let's start the conversation!
            </p>
          </div>
          <div className="contact-container">
            <div className="contact-content contact-details">
              <h4>Contact Information</h4>
              <h5>Social</h5>
              <div className="contact-social">
                <a
                  href="https://www.facebook.com/cdh.webcraft"
                  title="Facebook"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FaFacebook />
                </a>
                <a
                  href="https://github.com/shanigtech"
                  title="Twitter"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FaGithub />
                </a>
                <a
                  href="https://www.linkedin.com/in/shani-cdh/"
                  title="Instagram"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FaLinkedin />
                </a>
              </div>
              <h5>Reach Us</h5>
              <div className="details">
                <div className="contact-list">
                  <MdOutlinePhone />
                  <p>+92 323 594-8057</p>
                </div>
                <div className="contact-list">
                  <MdOutlineMail />
                  <p>contact@cdhwebcraft.com</p>
                </div>
                <div className="contact-list">
                  <MdOutlineMap />
                  <p>Gujar Khan, Rawalpindi PB, Pakistan 47850</p>
                </div>
              </div>
              <div className="google-map">
                <hr />
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d53381.009081454555!2d73.2677573813236!3d33.25829538133428!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x392005bea6c1b8cf%3A0xc1ef0608b44b1eab!2sGujar%20Khan%2C%20Rawalpindi%2C%20Punjab%2C%20Pakistan!5e0!3m2!1sen!2s!4v1726685645863!5m2!1sen!2s"
                  width={`100%`}
                  height="300"
                  style={{ border: 0 }}
                  allowFullScreen
                  loading="lazy"
                  referrerPolicy="no-referrer-when-downgrade"
                  title="Google Maps Location"
                />
              </div>
            </div>
            <div className="contact-content contact-form">
              <form action="#">
                <h3>Get in touch!</h3>
                <div>
                  <input type="text" name="name" id="name" required />
                  <label htmlFor="name">Name</label>
                </div>
                <div>
                  <input type="email" name="email" id="email" required />
                  <label htmlFor="email">Email</label>
                </div>
                <div>
                  <textarea
                    type="text"
                    name="message"
                    id="message"
                    required
                  ></textarea>
                  <label htmlFor="message">Message</label>
                </div>
                <div className="form-button">
                  <button type="submit" id="submit-btn">Send</button>
                </div>
              </form>
            </div>
          </div>
        </div>
        <NewsletterSection />
      </>
    );
  }
};

export default ContactPage;
