import React, { useEffect, useState } from 'react';
import { useOutletContext } from "react-router-dom";
import DevCodeItemCard from './DevCodeItemCard';
import Img from '../../media/code/tech.jpg';

// Array of hover styles
const hoverItems = [
  { Title: 'Hover Style 101', Img: Img, Link: '' },
  { Title: 'Hover Style 102', Img: Img, Link: '' },
  { Title: 'Hover Style 103', Img: Img, Link: '' },
  { Title: 'Hover Style 104', Img: Img, Link: '' },
  { Title: 'Hover Style 105', Img: Img, Link: '' },
  { Title: 'Hover Style 106', Img: Img, Link: '' }
];

const CodeHover = () => {
  const [currentItems, handleOutletItems] = useOutletContext();
  const [loading, setLoading] = useState(true); // Loading state

  useEffect(() => {
    const DevCodeLoaderTimer = 800;

    setLoading(true);
    handleOutletItems(hoverItems);

    // Simulate a delay for loading
    const loaderTimeout = setTimeout(() => {
      setLoading(false);
    }, DevCodeLoaderTimer); // 700ms delay for skeletons

    return () => clearTimeout(loaderTimeout); // Cleanup timeout
  }, [handleOutletItems]);

  return (
    <div className='DevCodeOutlets-outer'>
      {loading ? (
        // Skeleton loader while data is loading
        <>
          {currentItems.map((_, index) => (
            <div className="skeleton" key={index}></div>
          ))}
        </>
      ) : (
        // Render actual hover styles once loading is complete
        currentItems.map((hover, index) => (
          <DevCodeItemCard
            key={index}
            Title={hover.Title}
            Img={hover.Img}
            Link={hover.Link}
          />
        ))
      )}
    </div>
  );
};

export default CodeHover;