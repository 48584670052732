import React from "react";
import { Link } from "react-router-dom";

const DevCodeItemCard = (props) => {
  return (
    <Link to={props.Link} className="ioi-item watermark">
      <div className="ioi-img-outer">
        <img src={props.Img} alt="" />
      </div>
      <h4>{props.Title}</h4>
      <div className="watermark__card">
        <h1>CDH</h1>
      </div>
    </Link>
  );
};

export default DevCodeItemCard;
