import React from 'react';
import { Outlet } from 'react-router-dom';
import HeaderDashboard from '../inner/HeaderDashboard';
import SidebarDashboard from '../inner/SidebarDashboard';
import { Helmet } from 'react-helmet';

const DashboardLayout = () => {
  return (
    <div className="DashboardLayout">
      <Helmet>
        <title>Dashboard</title>
      </Helmet>

      <HeaderDashboard />
      <div className="main_container">
        <SidebarDashboard />
        <main>
          <Outlet />
        </main>
      </div>
    </div>
  );
};

export default DashboardLayout;
