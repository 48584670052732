import React, { useEffect, useState } from "react";
import { FaFacebook, FaGithub, FaLinkedin } from "react-icons/fa";
import { Link, NavLink, useLocation, useNavigate } from "react-router-dom";
import Logo from "../media/assets/cdh_logo.png";

const DevHeader = () => {
  const [search, setSearch] = useState('');
  const location = useLocation();
  const navigate = useNavigate();

  const handleSubmit = (event) => {
    event.preventDefault();
    // Update the URL with the search query without reloading the page
    navigate(`search?q=${encodeURIComponent(search)}`, { replace: true });
    console.log(`Search: ${search}`);
  };

  const handleSearchOnSearch = (event) => {
    if (event.key === 'Enter') {
      handleSubmit(event); // Pass the event to prevent form submission
    }
  };
  
  // Reset the search input on route change
  useEffect(() => {
    setSearch('');
  }, [location.pathname]);

  return (
    <menu className="DevHeader">
      <div className="logo">
        <img src={Logo} alt="Logo" />
      </div>
      <div style={{ width: '100%' }}>
        <div className="banner">
          <div>
            <form onSubmit={handleSubmit}>
              <input
                type="search"
                name={"search"}
                id="search"
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                placeholder="Search"
                onKeyDown={handleSearchOnSearch}
                required
              />
              <button type="submit">Search</button>
            </form>
          </div>
          <div>
            <div className="social">
              <a href="/#" rel="noopener" title="Facebook">
                <FaFacebook />
              </a>
            </div>
            <div className="social">
              <a href="/#" rel="noopener" title="Facebook">
                <FaLinkedin />
              </a>
            </div>
            <div className="social">
              <a href="/#" rel="noopener" title="Facebook">
                <FaGithub />
              </a>
            </div>
          </div>
        </div>
        <nav>
          <ul>
            <li>
              <NavLink to="/dev">Home</NavLink>
            </li>
            <li>
              <NavLink to="elements">Elements</NavLink>
            </li>
            <li>
              <NavLink to="templetes">Templetes</NavLink>
            </li>
            <li>
              <NavLink to="code-snippet">Code Snippet</NavLink>
            </li>
            <li>
              <NavLink to="courses">Courses</NavLink>
            </li>
          </ul>
        </nav>
      </div>
    </menu>
  );
};

export default DevHeader;
