import React, { useState } from "react";
import { IoSettings } from "react-icons/io5";
import { Link } from "react-router-dom";
import { BiLinkExternal } from "react-icons/bi";

import Logo from "../../../media/assets/cdh_logo.png";
import Shani from "../../../media/assets/Shani Profile.png";

const HeaderDashboard = () => {
  const [isCardVisible, setCardVisible] = useState(false);

  const handleDetailsCard = () => {
    setCardVisible(!isCardVisible);
  };

  return (
    <header className="HeaderDashboard">
      <div className="logo">
        <img src={Logo} alt="Logo" />
        <h3 className="admin">admin</h3>
      </div>
      <div className="info_area">
        <Link to='/' target="__blank__" className="icon external_link">
          <BiLinkExternal />
        </Link>
        <Link className="icon setting">
          <IoSettings />
        </Link>
        <div className="user_info">
          <div className="avatar" onClick={handleDetailsCard}>
            <img className="avatar_img" src={Shani} alt="User Avatar" />
          </div>
          <div
            className={`details ${isCardVisible ? "show" : ""}`}
            id="details_card"
          >
            <div className="banner">
              <div className="avatar">
                <img className="avatar_img" src={Shani} alt="User Avatar" />
              </div>
            </div>
            <h2 className="admin_name">Muhammad Zeeshan Ali</h2>
            <ul>
              <li>
                <Link to="">Admin Setting</Link>
              </li>
              <li>
                <Link to="">User Setting</Link>
              </li>
              <li>
                <Link to="">User Setting</Link>
              </li>
            </ul>
            <div
              style={{
                display: "flex",
                justifyContent: "end",
                alignItems: "center",
              }}
            >
              <button className="logout_btn">Logout</button>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default HeaderDashboard;
