import React from 'react'

const AboutPageFAQSkeleton = () => {
  return (
    <div className='AboutPageFAQSkeleton'>
      <div className="skeleton"></div>
      <div className="skeleton"></div>
      <div className="skeleton"></div>
      <div className="skeleton"></div>
    </div>
  )
}

export default AboutPageFAQSkeleton