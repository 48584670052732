import React from "react";
import { Link } from "react-router-dom";

const LatestThemeCard = (props) => {
  return (
    <Link to={props.url} className="LatestThemeCard watermark">
      <img src={props.img} alt="" />
      <div className="watermark__card">
        <h1>CDH</h1>
      </div>
    </Link>
  );
};

export default LatestThemeCard;
