import React, { useEffect, useState } from "react";
import axios from 'axios';

const NewsletterSection = () => {
  const [loading, setLoading] = useState(true);
  const [input, setInput] = useState({ subscriberMail: "" });
  const [message, setMessage] = useState("");

  useEffect(() => {
    const randomSeconds = Math.random() * 900;
    // Simulate loading time
    const timer = setTimeout(() => {
      setLoading(false);
    }, randomSeconds);

    return () => clearTimeout(timer);
  }, []);

  const handleSubscribeChange = (event) => {
    const { name, value } = event.target;
    setInput((prevValues) => ({ ...prevValues, [name]: value }));
  };

  const handleSubscribeSubmit = (event) => {
    event.preventDefault();

    // Send the input to the backend API
    axios.post('http://localhost/cdh-digital-agency/frontend/api/index.php/api/subscriber/save', input)
      .then(response => {
        if (response.data.status === 1) {
          setMessage("Subscription successful!");
          setInput({ subscriberMail: "" }); // Clear input after successful subscription
        } else {
          setMessage(response.data.message);
        }
      })
      .catch(error => {
        console.error("Error in subscription:", error);
        setMessage("An error occurred. Please try again.");
      });
  };

  return (
    <div></div>
    // <div className="NewsletterSection">
    //   {loading ? (
    //     <p>Loading...</p>
    //   ) : (
    //     <>
    //       <form onSubmit={handleSubscribeSubmit}>
    //         <input
    //           type="email"
    //           name="subscriberMail"
    //           id="subscriberMail"
    //           placeholder="example@gmail.com"
    //           onChange={handleSubscribeChange}
    //           value={input.subscriberMail}
    //           required
    //         />
    //         <button type="submit">Subscribe</button>
    //       </form>
    //       {message && <p>{message}</p>}
    //     </>
    //   )}
    // </div>
  );
};

export default NewsletterSection;
