import React, { useState, useEffect } from "react";
import FAQListItem from "./innercomponents/FAQListItem";
import AboutPageTextSkeleton from "./skeletons/AboutPageTextSkeleton";
import AboutPageFAQSkeleton from "./skeletons/AboutPageFAQSkeleton";
import { Link } from "react-router-dom";

const AboutPage = () => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const skeletons =
      document.querySelectorAll(".skeleton") ||
      document.querySelectorAll(".AboutPageTextSkeleton.skeleton");
    const SkeletonDelay = Math.random() * 2;

    skeletons.forEach((skeleton) => {
      if (!skeleton) return;
      skeleton.style.animationDelay = `${SkeletonDelay}s`;
    });
  }, []);

  useEffect(() => {
    const TimeSecond = Math.random() * 700;
    const timer = setTimeout(() => {
      setLoading(false);
    }, TimeSecond);

    return () => clearTimeout(timer);
  }, []);

  const faqs = [
    {
      id: 1,
      question: "What services do you offer?",
      answer:
        "We provide custom web development, pre-built templates, and a library of web components.",
    },
    {
      id: 2,
      question: "How can I start a project with CDH WebCraft?",
      answer:
        "You can place orders directly through Upwork or contact us for custom projects.",
    },
    {
      id: 3,
      question: "Why choose CDH WebCraft?",
      answer:
        "We focus on quality, creativity, and technical precision to exceed your expectations.",
    },
  ];

  return (
    <div>
      <h2 className="who-we-are-style">Who We Are?</h2>
      <div className="about-page-body">
        <div className="ab-pg-break about-container">
          <h4>About Us</h4>
          {loading ? <AboutPageTextSkeleton /> : <AboutText />}
        </div>
        <div className="ab-pg-break about-faq">
          <h4>FAQs</h4>
          {loading ? <AboutPageFAQSkeleton /> : <FAQList faqs={faqs} />}
        </div>
      </div>
    </div>
  );

  function AboutText() {
    return (
      <>
        <p>
          <strong>
            Turning Vision into Reality with Expertly Crafted Web Solutions for
            a Digital-First World.
          </strong>{" "}
          At <strong>CDH WebCraft</strong>, the focus is on delivering
          cutting-edge solutions that cater to unique needs, ensuring every
          project is completed{" "}
          <strong>affectionately and before the deadline</strong>.
        </p>

        <h2>Who We Are</h2>
        <p>
          With extensive experience in{" "}
          <strong>HTML, CSS, JavaScript, React, PHP, and MongoDB</strong>, we
          specialize in creating dynamic, responsive, and feature-rich websites.
          From sleek portfolios to robust e-commerce platforms, the goal is to
          turn your vision into a reality with precision and attention to
          detail.
        </p>

        <h2>What We Offer</h2>
        <ul style={{ color: "var(--parablack)" }}>
          <li>
            <strong>Custom Web Development:</strong> Tailored websites that
            align with your brand and business goals.
          </li>
          <li>
            <strong>Pre-built Website Templates:</strong> A selection of
            ready-to-use, fully customizable templates optimized for
            performance.
          </li>
          <li>
            <strong>Development Tools & Web Elements:</strong> An extensive
            library of web components and tools, complete with resource links.
          </li>
          <li>
            <strong>Full Stack Expertise:</strong> Seamless integration of
            front-end design and back-end functionality to deliver
            high-performance web solutions.
          </li>
        </ul>

        <p>
          For those looking to bring their projects to life, orders can easily
          be placed through{" "}
          <a href="https://upwork.com/" target="_blank_">
            Upwork
          </a>{" "}
          or other freelancing platforms. Simply visit our profile or get in
          touch for more details. We look forward to collaborating on your next
          big project!
        </p>

        <h2>Why Choose Us?</h2>
        <p>
          Our work is a blend of <strong>creativity</strong> and{" "}
          <strong>technical excellence</strong>, ensuring that each project is
          delivered on time and exceeds expectations. Every website is crafted
          to not only look stunning but to perform seamlessly across all
          devices, making sure you succeed in a{" "}
          <strong>digital-first world</strong>.
        </p>

        <h2>Privacy Policy</h2>
        <p>
          At <strong>CDH WebCraft</strong>, we are committed to protecting your
          privacy. We collect and use your personal data only to provide and
          improve our services. Any information collected is kept strictly
          confidential and will never be shared with third parties without your
          consent.
        </p>
        <p>
          We may collect your name, email address, and project details when you
          contact us. This information is solely used to facilitate
          communication and deliver the services you require. If you have any
          concerns about our privacy practices, feel free to{" "}
          <Link to="/contact">contact us</Link>.
        </p>
      </>
    );
  }

  function FAQList({ faqs }) {
    return (
      <>
        {faqs.map((faq, index) => (
          <FAQListItem
            key={faq.id}
            Id={faq.id.toString()}
            Question={faq.question}
            Answer={faq.answer}
            defaultChecked={index === 0} // Only the first FAQ will be checked by default
          />
        ))}
      </>
    );
  }
};

export default AboutPage;
