import React from 'react'

const ContactPageSkeleton = () => {
  return (
    <div className='contact-skeleton'>
      <div className="skeleton contact-head-skeleton"></div>
      <div className='c-s-side'>
        <div>
          <div className="skeleton"></div> {/* 1 */}
          <div className="skeleton"></div> {/* 2 */}
          <div className="skeleton"></div> {/* 3 */}
        </div>
        <div>
          <div className="skeleton"></div>
        </div>
      </div>
    </div>
  )
};

export default ContactPageSkeleton