import React, { useEffect } from 'react';
import BannerImage from '../media/img/Banner-Laptop.png';

import { FaCss3, FaHtml5, FaJava, FaPhp, FaReact } from "react-icons/fa";

const Banner = () => {

  useEffect(() => {
    // Select only the icons within this component
    const icons = document.querySelectorAll('.visual .icon');
    
    icons.forEach(icon => {
      const randomDelay = Math.random() * 2; // Random delay between 0 and 2 seconds
      icon.style.animationDelay = `${randomDelay}s`;
    });

  }, []);

  return (
    <div className='main-banner'>
      <div className='container'>
        <div className="content">
          <h5>
            <span>
              The Best
            </span>
            Development House
          </h5>
          <h2>
            <span className='outlined-heading'>
              Exceed&nbsp;
            </span>
            Your <br /> Ideal Website
          </h2>
          <p>
            Turning Vision into Reality with Expertly Crafted Web Solutions for a Digital-First World.
          </p>
        </div>
        <div className="visual">
          <div className="icon javascript">
            <FaJava />
          </div>
          <div className="icon php">
            <FaPhp />
          </div>
          <div className="icon react">
            <FaReact />
          </div>
          <div className="icon html">
            <FaHtml5 />
          </div>
          <div className="icon css">
            <FaCss3 />
          </div>
          <img src={BannerImage} alt="" width={800}/>
        </div>
      </div>
    </div>
  );
}

export default Banner;
