import React, { useEffect, useState } from 'react'
import Img__1 from '../media/code/blue neon.jpg'
import Img__3 from '../media/code/tech.jpg'
import Img__2 from '../media/code/buttons.jpg'
import LatestThemeCard from './dev_home_inner/LatestThemeCard'
import { useLocation, useNavigate } from 'react-router-dom'
import ElementSwiperElementHome from './dev_home_inner/ElementSwiperElementHome'

const DevHomePage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const loaderTimer = setTimeout(() => {
      setLoading(false);
    }, 700);
  
    // Cleanup the timer on component unmount or when location changes
    return () => clearTimeout(loaderTimer);
  }, [location.pathname]);
  
  const latestThemes = [
    { img: Img__1, url: '#'},
    { img: Img__2, url: '#'},
    { img: Img__3, url: '#'},
    { img: Img__3, url: '#'},
    { img: Img__2, url: '#'},
    { img: Img__1, url: '#'},
  ];
  
  return (
    <div className='DevHomePage'>
      <div className="latest__themes">
        {latestThemes.map((theme, index) => (
          loading ? (
            <div className="skeleton"></div>
          ) : 
          <LatestThemeCard
            img={theme.img}
            url={theme.url}
          />
        ))}
      </div>
      <div className="head" style={{ marginTop: '20px',}}>
        <div className="dev-page-heading">Elements</div>
        <div className="view__all__btn">
          <button
            onClick={() => {
              navigate('/dev/elements')
            }}
          >
            View All
          </button>
        </div>
      </div>
      <section>
        <ElementSwiperElementHome />
      </section>
    </div>
  )
}

export default DevHomePage