import React from 'react';
import { useLocation } from 'react-router-dom';

const DevSearchResult = () => {
  const location = useLocation();

  // Extract query parameters from the URL
  const queryParams = new URLSearchParams(location.search);
  const searchQuery = queryParams.get('q') || ''; // Assuming the query parameter is named "q"

  // Example data to simulate search results
  const searchResults = ["developer", "react app", "frontend developer", "backend developer", "fullstack", "javascript", "css"]; // Sample data

  // Function to check if at least 3 letters match any item in the searchResults array
  const hasPartialMatch = (term) => {
    return searchResults.filter(result => result.toLowerCase().includes(term.toLowerCase()));
  };

  // Ensure the search query has at least 3 characters
  const filteredResults = searchQuery.length >= 3 ? hasPartialMatch(searchQuery) : [];

  return (
    <div>
      {searchQuery ? (
        filteredResults.length > 0 ? (
          <div className='search-list-container'>
            <ul>
              {filteredResults.map((result, index) => (
                <li className='search-list' key={index}>{result}</li>
              ))}
            </ul>
          </div>
        ) : (
          <div>
            <h1>No Results Found</h1>
            <p>Sorry, we couldn't find any results for "<strong>{searchQuery}</strong>".</p>
            <p>Please try searching with different keywords or at least 3 letters.</p>
          </div>
        )
      ) : (
        <div>
          <h1>No Search Query Provided</h1>
          <p>Please enter a search term.</p>
        </div>
      )}
    </div>
  );
};

export default DevSearchResult;
