import React, { useEffect, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination } from 'swiper/modules';

import Img__1 from '../../media/code/tech.jpg'
import Img__2 from '../../media/code/buttons.jpg'

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import { Link, useLocation } from 'react-router-dom';

export default function ElementSwiperElementHome() {
  const location = useLocation('');
  const [loading, setLoading] = useState(true);

  const slides = [
    { img: Img__1, url: '#' },
    { img: Img__2, url: '#' },
    { img: Img__1, url: '#' },
    { img: Img__2, url: '#' },
    { img: Img__1, url: '#' },
    { img: Img__2, url: '#' },
  ];

  useEffect(() => {
    const loadTimer = setTimeout(() => {
      setLoading(false);
    }, 700);

    return () => clearTimeout(loadTimer);
  }, [location.pathname])

  return (
    <>
      <Swiper
        slidesPerView={3}
        spaceBetween={20}
        pagination={{
          clickable: true,
        }}
        modules={[Pagination]}
        className="mySwiper"
      >
        {/* Add images to each SwiperSlide */}
        {slides.map((slide, index) => (
          <SwiperSlide key={index}>
            {loading ? (
              <div className="skeleton"></div>
            ) :
            <Link to={slide.url}>
             <img src={slide.img} alt="Elements Slides" />
           </Link>
           }
          </SwiperSlide>
        ))}
      </Swiper>
    </>
  );
}
