import React from 'react'
import { Link } from 'react-router-dom'

const FooterDev = () => {
  return (
    <footer className='FooterDev'>
      <div className="upper">
        <h4>
          <span>contact@cdhwebcraft.com</span>
          <span>|</span>
          <span>cdhwebcraft@gmail.com</span>
          <span>|</span>
          <span>+92 323 594-8057</span>
        </h4>
      </div>
      <div className="lower">
        <ul>
          <li><Link to='#'>About</Link></li>
          <li><Link to='#'>Privacy Policy</Link></li>
        </ul>
        <p>Copyrights &copy; CDH All Rights Reserved</p>
      </div>
    </footer>
  )
}

export default FooterDev