// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/pagination';

// import required modules
import { FreeMode, Pagination } from 'swiper/modules';

import StudioVIP from "../media/projects/Studio_VIP.png";
import CDHWebCraft from "../media/projects/CDH_WebCraft.png";
import CreativeSolutions from "../media/projects/Creative_Solutions.png";

const ServicesSwiper = () => {
  return (
    <>
      <h1 className='section-heading'>Our Recents</h1>
      <Swiper
        slidesPerView={3}
        spaceBetween={30}
        freeMode={true}
        pagination={{
          clickable: true,
        }}
        modules={[FreeMode, Pagination]}
        className="mySwiper portfolio-designs"
      >
        <SwiperSlide>
          <div class="project-box">
            <a
              href="https://www.dummy.nxmuni.com"
              title="Studio VIP"
              target="_blank__"
              rel="noopener"
            >
              <img src={StudioVIP} alt="Studio VIP" />
            </a>
            <h4>
              <a
                href="https://www.dummy.nxmuni.com"
                title="Studio VIP"
                target="_blank_"
                rel="noopener"
              >
                Studio VIP
              </a>
            </h4>
            <p>DJ's Website</p>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div class="project-box">
            <a
              href="https://cdhwebcraft.com"
              title="CDH WebCraft"
              target="_blank_"
              rel="noopener"
            >
              <img src={CDHWebCraft} alt="CDH WebCraft" />
            </a>
            <h4>
              <a
                href="https://cdhwebcraft.com"
                title="CDH WebCraft"
                target="_blank_"
                rel="noopener"
              >
                CDH WebCraft
              </a>
            </h4>
            <p>Portfolio Website</p>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div class="project-box">
            <a
              href="https://creativesolutions.live/"
              title="Creative Solutions"
              target="_blank_"
              rel="noopener"
            >
              <img src={CreativeSolutions} alt="Creative Solutions" />
            </a>
            <h4>
              <a
                href="https://creativesolutions.live/"
                title="Creative Solutions"
                target="_blank_"
                rel="noopener"
              >
                Creative Solutions
              </a>
            </h4>
            <p>Business Website</p>
          </div>
        </SwiperSlide>
        {/* <SwiperSlide></SwiperSlide> */}
      </Swiper>
    </>
  );
};

export default ServicesSwiper;
