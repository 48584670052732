import React, { useEffect, useState } from 'react';
import { useOutletContext } from "react-router-dom";
import DevCodeItemCard from './DevCodeItemCard';
import Img from '../../media/code/blue neon.jpg';

const formItems = [
  { Title: 'Form Style 101', Img: Img, Link: '' },
  { Title: 'Form Style 102', Img: Img, Link: '' },
  { Title: 'Form Style 103', Img: Img, Link: '' },
  { Title: 'Form Style 104', Img: Img, Link: '' },
  { Title: 'Form Style 105', Img: Img, Link: '' }
];

const CodeForm = () => {
  const [currentItems, handleOutletItems] = useOutletContext();
  const [loading, setLoading] = useState(true); // Loading state

  useEffect(() => {
    const DevCodeLoaderTimer = 800;

    setLoading(true);
    handleOutletItems(formItems);

    // Simulate a delay for loading
    const loaderTimeout = setTimeout(() => {
      setLoading(false);
    }, DevCodeLoaderTimer); // 700ms delay for skeletons

    return () => clearTimeout(loaderTimeout); // Cleanup timeout
  }, [handleOutletItems]);

  return (
    <div className='DevCodeOutlets-outer'>
      {loading ? (
        // Skeleton loader when data is loading
        <>
          {currentItems.map((_, index) => (
            <div className="skeleton" key={index}></div>
          ))}
        </>
      ) : (
        // Render actual items once loading is done
        currentItems.map((form, index) => (
          <DevCodeItemCard
            key={index}
            Title={form.Title}
            Img={form.Img}
            Link={form.Link}
          />
        ))
      )}
    </div>
  );
};

export default CodeForm;
