import React, { useState, useEffect } from "react";
import { useNavigate, Outlet, useLocation } from "react-router-dom";
import PaginationSection from "./innercomponents/PaginationSection";

const DevCodePage = () => {
  const [currentHeading, setCurrentHeading] = useState(""); // State to hold the current page title
  const [ currentPage, setCurrentPage ] = useState(1);
  const navigate = useNavigate();
  const location = useLocation();
  const itemPerPage = 15;
  const [ outletItems, setOutletItems ] = useState([])
  
  const handleOutletItems = (items) => {
    setOutletItems(items);
  }

  // Clear heading when the component mounts
  useEffect(() => {
    const path = location.pathname.split("/").pop(); // Get the last segment of the path
    const headings = {
      button: "Buttons",
      hover: "Hover",
      form: "Form",
      table: "Table",
      list: "List",
    };

    setCurrentHeading(headings[path] || "");
  }, [location.pathname]);

  const totalPages = Math.ceil(outletItems.length / itemPerPage);

  const currentItems = outletItems.slice((currentPage - 1) * itemPerPage, currentPage * itemPerPage)

  return (
    <div className="DevCode">
      {/* Show buttons if no component is selected */}
      {!currentHeading && (
        <div className="DevCodePage">
          <button className="code-type" onClick={() => {
            setCurrentHeading("Buttons");
            navigate("/dev/code-snippet/button");
          }}>
            Buttons
          </button>
          <button className="code-type" onClick={() => {
            setCurrentHeading("Hover");
            navigate("/dev/code-snippet/hover");
          }}>
            Hover
          </button>
          <button className="code-type" onClick={() => {
            setCurrentHeading("Form");
            navigate("/dev/code-snippet/form");
          }}>
            Form
          </button>
          <button className="code-type" onClick={() => {
            setCurrentHeading("Table");
            navigate("/dev/code-snippet/table");
          }}>
            Table
          </button>
          <button className="code-type" onClick={() => {
            setCurrentHeading("List");
            navigate("/dev/code-snippet/list");
          }}>
            List
          </button>
        </div>
      )}

      {/* Show the page title and the selected component */}
      {currentHeading && (
        <div className="head">
          <h1 className="dev-page-heading">{currentHeading}</h1>
          {/* Link view for current selection */}
          <div className="link-view">
            <span>
              <button onClick={() => navigate('/dev/code-snippet')}>Code</button>
              <span>/</span>
              <span>{currentHeading}</span>
            </span>
          </div>
        </div>
      )}

      {/* Nested routes will be displayed here */}
      <Outlet context={[currentItems, handleOutletItems]} />

      {/* Pagination only if items exceed the limit */}
      {outletItems.length > itemPerPage && (
        <PaginationSection
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          totalPages={totalPages}
        />
      )}
    </div>
  );
};

export default DevCodePage;
