import React from 'react'

const ChoosenListItem = (props) => {
  return (
    <div className='ChoosenListItem'>
      <div className="icon-box">
        <span>{props.Icon}</span>
      </div>
      <div className="list-item-content">
        <h5>{props.Heading}</h5>
        <p>{props.Desc}</p>
      </div>
    </div>
  )
}

export default ChoosenListItem