import React, { useEffect, useState } from "react";
import { Link, Navigate, NavLink, useLocation, useNavigate } from "react-router-dom";
import Logo from "../media/assets/cdh_logo.png";

const MainHeader = () => {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const header = document.querySelector("header");
    const title = document.getElementById("title");
    const MenuLinks = document.querySelectorAll(".menu-link");

    const updateStyles = () => {
      const isNotHome = location.pathname !== "/";
      const scrollTop =
        document.documentElement.scrollTop || document.body.scrollTop;
      const isScrolled = scrollTop > 250;

      if (title) {
        title.style.color =
          isNotHome || isScrolled ? "var(--black)" : "var(--white)";
        title.style.fontSize = isScrolled ? "40px" : "";
      }

      MenuLinks.forEach((link) => {
        link.style.color =
          isNotHome || isScrolled ? "var(--black)" : "var(--white)";
      });

      if (header) {
        header.style.height = isScrolled ? "60px" : "";
        header.style.position = isScrolled ? "fixed" : "absolute";
        header.style.top = isScrolled ? "0" : "";
        header.style.backgroundColor = isScrolled ? "var(--white)" : "";
      }
    };

    updateStyles();

    const HandleScroll = () => {
      updateStyles();
    };

    window.addEventListener("scroll", HandleScroll);

    return () => {
      window.removeEventListener("scroll", HandleScroll);
    };
  }, [location.pathname]);

  return (
    <header className="main_header">
      <div>
        <a href="/" className="logo">
          <img src={Logo} alt="WebCraft Agency Logo" id="logo" />
          <h1 id="title">WebCraft Agency</h1>
        </a>
        <menu>
          <ul>
            <li>
              <NavLink className="menu-link" to="/">
                Home
              </NavLink>
            </li>
            <li>
              <NavLink className="menu-link" to="/services">
                Services
              </NavLink>
            </li>
            <li>
              <NavLink className="menu-link" to="/about">
                About
              </NavLink>
            </li>
            <li>
              <NavLink className="menu-link" to="/contact">
                Contact
              </NavLink>
            </li>
            <li>
                <button onClick={() => {navigate('#')}} className="header-btn-object" type="button">
                  Web & Blogs
                </button>
            </li>
          </ul>
        </menu>
      </div>
    </header>
  );
};

export default MainHeader;
