import React, { useEffect, useState } from 'react';
import { useOutletContext, useLocation } from "react-router-dom";
import DevCodeItemCard from './DevCodeItemCard';
import Img from '../../media/code/tech.jpg';

const listItems = [
  { Title: 'List Style 101', Img: Img, Link: '' },
  { Title: 'List Style 102', Img: Img, Link: '' },
  { Title: 'List Style 103', Img: Img, Link: '' }
];

const CodeList = () => {
  const [currentItems, handleOutletItems] = useOutletContext();
  const [loading, setLoading] = useState(true);
  const location = useLocation();

  useEffect(() => {
    const DevCodeLoaderTimer = 800;

    setLoading(true);
    handleOutletItems(listItems);

    const loaderTimeout = setTimeout(() => {
      setLoading(false);
    }, DevCodeLoaderTimer);

    return () => clearTimeout(loaderTimeout);
  }, [handleOutletItems, location.pathname]);

  return (
    <div className='DevCodeOutlets-outer'>
      {loading ? (
        <>
          {currentItems.map((_, index) => (
            <div className="skeleton" key={index}></div>
          ))}
        </>
      ) : (
        currentItems.map((list, index) => (
          <DevCodeItemCard
            key={index}
            Title={list.Title}
            Img={list.Img}
            Link={list.Link}
          />
        ))
      )}
    </div>
  );
};

export default CodeList;
