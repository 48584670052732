import React from 'react'
import { AiFillLike } from 'react-icons/ai'
import { FaComment } from 'react-icons/fa'
import { IoIosEye } from 'react-icons/io'
import { IoCubeOutline } from 'react-icons/io5'
import { MdDownloadForOffline } from 'react-icons/md'
import { Link } from 'react-router-dom'

const DevTempleteCard = (props) => {
  return (
    <li className="templete-item" key={props.key}>
      <Link to={props.to} onClick={props.onClick} className="img">
        <img src={props.img} alt="template thumbnail" />
      </Link>
      <div className="details">
        <div className="language">
          <span>
            <IoCubeOutline />
          </span>
          <h5>
            <span>{props.tech}</span>
          </h5>
        </div>
        <Link to={props.to} onClick={props.onClick}>
          <h1 className="title">
            {props.title}
          </h1>
        </Link>
        <p className="short-description">
          {props.paragraph}
        </p>
        <div className="stats-container">
          <div className="stat views-counter">
            <span className="icon"><IoIosEye /></span>
            <span className="count">1234</span>{" "}
          </div>
          <div className="stat likes-counter">
            <span className="icon"><AiFillLike /></span>
            <span className="count">789</span>{" "}
          </div>
          <div className="stat comments-counter">
            <span className="icon"><FaComment /></span> <span className="count">32</span>{" "}
          </div>
          <div className="stat downloads-counter">
            <span className="icon">Downloads:</span>
            <span className="count">567</span>{" "}
          </div>
          <div className="download-btn">
            <MdDownloadForOffline />
          </div>
        </div>
      </div>
    </li>
  )
}

export default DevTempleteCard