import React, { useEffect, useState } from "react";
import Laraval from '../media/templetes/laravel.png';
import { IoSearchOutline } from "react-icons/io5"; // Adding search icon
import PaginationSection from "./innercomponents/PaginationSection";
import { useLocation } from "react-router-dom";
import ModalContainer from "./innercomponents/ModalContainer";
import DevTempleteCard from "./innercomponents/DevTempleteCard";

const DevTempletesPage = () => {
  const location = useLocation();
  const [loading, setLoading] = useState(true); // Set loading to true initially
  const [searchTerm, setSearchTerm] = useState('');
  const [filter, setFilter] = useState('all');
  const [currentPage, setCurrentPage] = useState(1);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedTemplate, setSelectedTemplate] = useState(null); // State to track the selected template
  const itemsPerPage = 8; 

  // Example list of templates
  const templates = [
    {
      title: "Laravel E-commerce Website Template for Building Professional Online Stores",
      paragraph: "This Laravel e-commerce template provides all the features necessary to build a professional online store. With modern UI components and integrations, it's designed for scalability and flexibility. Technologies used include HTML for structuring the layout and React for dynamic front-end interactions.",
      category: "free",
      language: "HTML | React",
      url: "#",
      img: Laraval
    },
    {
      title: "Advanced React Admin Dashboard with Analytics and Management Tools",
      paragraph: "A comprehensive React-based admin dashboard that comes equipped with various charts, analytics, and management tools to handle large datasets. The template includes a user-friendly interface, customizable themes, and real-time data integration. It's suitable for enterprise-level applications and small businesses alike.",
      category: "paid",
      language: "React | JS",
      url: "#",
      img: Laraval
    },
    {
      title: "Beginner-Friendly CSS Framework for Building Responsive Web Pages",
      paragraph: "This template provides a basic CSS framework designed for those who are new to web development. It includes a library of reusable components, such as buttons, modals, and grid systems, making it easy for beginners to create responsive web pages.",
      category: "free",
      language: "CSS | HTML",
      url: "#",
      img: Laraval
    },
    {
      title: "Fullstack Web Application Template with React and Node.js Integration",
      paragraph: "An advanced fullstack template that integrates React for front-end and Node.js for back-end development. Ideal for building scalable web applications, this template includes authentication, routing, and API integration out-of-the-box. It supports MongoDB for database management.",
      category: "paid",
      language: "React | Node.js",
      url: "#",
      img: Laraval
    },
    {
      title: "Vue.js Admin Panel for Enterprise-Level Applications with Real-Time Analytics",
      paragraph: "A sleek and modern admin panel template developed using Vue.js. It includes a customizable dashboard, real-time analytics, and built-in user management features. Perfect for handling enterprise applications or SaaS products.",
      category: "paid",
      language: "Vue.js | JS",
      url: "#",
      img: Laraval
    },
    {
      title: "Minimalistic Bootstrap Landing Page Template for Startups and Product Launches",
      paragraph: "This free Bootstrap-based landing page template is perfect for startups or product launches. It includes a variety of sections, such as testimonials, features, and pricing, all designed with a clean and minimalistic aesthetic.",
      category: "free",
      language: "Bootstrap | HTML | CSS",
      url: "#",
      img: Laraval
    },
    {
      title: "React E-commerce Website Template with Redux for State Management",
      paragraph: "An advanced React e-commerce site template that includes Redux for state management, payment integrations, and a fully functional shopping cart. Ideal for businesses looking to launch a scalable and modern online store.",
      category: "paid",
      language: "React | Redux",
      url: "#",
      img: Laraval
    },
    {
      title: "Modern Personal Portfolio Template for Freelancers and Developers",
      paragraph: "A modern personal portfolio template built using HTML, CSS, and JavaScript. This template is perfect for freelancers or developers to showcase their work, skills, and resume. It includes sections for projects, experience, and contact information.",
      category: "free",
      language: "HTML | CSS | JS",
      url: "#",
      img: Laraval
    },
    {
      title: "Angular CRM Template for Managing Customers, Leads, and Sales",
      paragraph: "This Angular CRM template provides a fully responsive and feature-rich customer relationship management system. It includes tools for managing leads, customers, and sales, with integrated dashboards for visualizing business data.",
      category: "paid",
      language: "Angular | TypeScript",
      url: "#",
      img: Laraval
    },
    {
      title: "TailwindCSS Starter Template for Quickly Building Responsive Web Pages",
      paragraph: "A minimal starter template designed with TailwindCSS, ideal for quickly building responsive web pages. It comes with a collection of pre-styled components and utilities, allowing developers to build layouts without writing custom CSS.",
      category: "free",
      language: "TailwindCSS | HTML",
      url: "#",
      img: Laraval
    }
  ];

  // Filter the templates based on search term and filter category
  const filteredTemplates = templates.filter((template) => {
    const matchesSearch = template.title.toLowerCase().includes(searchTerm.toLowerCase());
    const matchesFilter = filter === 'all' || template.category === filter;
    return matchesSearch && matchesFilter;
  });

  // Calculate total pages and items for current page
  const totalPages = Math.ceil(filteredTemplates.length / itemsPerPage);
  const currentTemplates = filteredTemplates.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);

  useEffect(() => {
    const DevCodeLoaderTimer = 700;
    // Simulate loading by setting a timeout of 700ms
    setLoading(true);
    const timer = setTimeout(() => {
      setLoading(false);
    }, DevCodeLoaderTimer);

    // Cleanup the timeout when the component unmounts or pathname changes
    return () => clearTimeout(timer);
  }, [location.pathname]);

  return (
    <div className="DevTempletesPage">
      {/* Search Bar with Search Input and Filter */}
      <div className="search-bar">
        <div className="search-input">
          <IoSearchOutline className="search-icon" />
          <input
            type="text"
            placeholder="Search templates..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)} // Update search term on input change
          />
          <div className="filter">
            <select name="filter" id="filter" value={filter} onChange={(e) => setFilter(e.target.value)}>
              <option value="all">All</option>
              <option value="free">Free</option>
              <option value="paid">Paid</option>
            </select>
          </div>
        </div>
      </div>

      {/* Template List */}
      <ul className="templete-list">
        {loading ? (
          // Show skeleton loader when loading is true
          Array.from({ length: itemsPerPage }).map((_, index) => (
            <li className="templete-item skeleton__outer" key={index}>
              <div className="skeleton"></div>
              <div className="skeleton"></div>
            </li>
          ))
        ) : (
          currentTemplates.length > 0 ? (
            currentTemplates.map((template, index) => (
              <DevTempleteCard
                key={index}
                to={template.url}
                img={template.img}
                tech={template.language}
                title={template.title}
                paragraph={template.paragraph}
                onClick={() => {
                  setSelectedTemplate(template);
                  setIsModalVisible(true);
                }} // Show modal on click
              />
            ))
          ) : (
            <p>No templates found</p>
          )
        )}
      </ul>

      {/* Modal Container */}
      {selectedTemplate && (
        <ModalContainer
          title={selectedTemplate.title}
          language={selectedTemplate.language}
          isVisible={isModalVisible}
          onClose={() => setIsModalVisible(false)}
        />
      )}

      {/* Pagination Controls */}
      {totalPages > 1 && (
        <PaginationSection 
          currentPage={currentPage} 
          setCurrentPage={setCurrentPage} 
          totalPages={totalPages} 
        />
      )}
    </div>
  );
};

export default DevTempletesPage;