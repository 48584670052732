import React, { useEffect, useState } from 'react';
import DevHeader from './DevHeader';
import { Outlet, useLocation } from 'react-router-dom';
import DevSideBarMenu from './DevSideBarMenu';
import DevHomePage from './DevHomePage';
import DevRightSideBar from './DevRightSideBar';

const DevPageLayout = () => {
  const location = useLocation();

  // Extract the search query from URL for dynamic heading
  const queryParams = new URLSearchParams(location.search);
  const searchQuery = queryParams.get('q') || '';

  const pageHeadings = {
    '/dev': 'Themes',
    '/dev/elements': 'Elements',
    '/dev/templates': 'Templates', // Fixed typo here
    '/dev/search': `Search Results for: ${searchQuery}`,
    '/dev/code-snippet': 'Code Snippets',
  };

  const isDevHomePage = location.pathname === '/dev';
  const isInvalidSlug = !Object.keys(pageHeadings).includes(location.pathname);

  // Get the current page heading based on the route
  const currentHeading = pageHeadings[location.pathname] || 'Page Not Found';

  return (
    <div className='DevPageLayout'>
      <DevHeader />
      <div className="dev__main__content__container">
        <DevSideBarMenu />
        <section className="dev-container">
          <div className="section">
            {!isInvalidSlug && (
              <div className="head">
                <h1 className='dev-page-heading'>{currentHeading}</h1>
              </div>
            )}
            <article style={{ padding: '0 3px' }}>
              {isDevHomePage ? (
                <DevHomePage />
              ) : (
                <Outlet />
              )}
            </article>
          </div>
        </section>
          <aside className="sidebar">
            <DevRightSideBar />
          </aside>
      </div>
    </div>
  );
};

export default DevPageLayout;
